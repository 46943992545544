import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';


const useStyles = makeStyles((theme )=> ({
  root: {
    "& h2": {
      fontSize: "35px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "20px"
      }
    }
  }
}));


const Logo = (props) => {
  const classes = useStyles();
  return (<Box className={classes.root}>
    <Typography variant='h2'>ProCryptoWallet</Typography>
  </Box>)
}


export default Logo;

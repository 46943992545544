import React, { useState } from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
  List,
  Link,
  ListItem,
  IconButton,
} from "@material-ui/core";
import { Link as RouterLink, useHistory } from "react-router-dom";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { FaMobile } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import Logo from "src/component/Logo";



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#192255",
    position: "relative",
    padding: "50px 0px",
    backgroundPosition: " bottom left",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    "& h5": {
      fontWeight: "bold",
      fontSize: "16px",
      letterSpacing: "2px",
      textTransform: "uppercase",
    },
    '& ul': {
      paddingLeft: "0",
    },
    "& p": {
      marginBottom: "0px",
      // marginTop: "10px",
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "18px",
      color: "#000000",

    },
  },
  footerText1: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center"
    },
    "& h5": {
      fontFamily: "'Roboto', sans-serif",
      textTransform: "capitalize",
      fontSize: "18px"
    },
    "& p": {
      color: "#898989",
      padding: "5px 0px",
      fontFamily: "'Roboto', sans-serif",
      cursor: "pointer",
      fontSize: "15px",
      fontWeight: "400",
      wordBreak: "break-all",
      "&:hover": {
        color: "#FFFFFF"
      }
    },
    "& ul": {
      "& li": {
        "& a": {
          display: "flex",
          alignItems: "center",
          "&:hover": {
            textDecoration: "none",
            color: "#fff"
          }
        }
      }
    },
  },
  footerText2: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    // [theme.breakpoints.down("sm")]: {
    //   alignItems: "center"
    // },
    "& h5": {
      fontFamily: "'Roboto', sans-serif",
      textTransform: "capitalize",
      fontSize: "18px",
      textAlign: "left"
    },
    "& p": {
      color: "#898989",
      padding: "5px 0px",
      fontFamily: "'Roboto', sans-serif",
      cursor: "pointer",
      fontSize: "15px",
      fontWeight: "400",
      wordBreak: "break-all",
      "&:hover": {
        color: "#FFFFFF"
      }
    },
    "& ul": {
      "& li": {
        "& a": {
          display: "flex",
          alignItems: "center",
          "&:hover": {
            textDecoration: "none",
            color: "#fff"
          }
        }
      }
    },
  },
  footerText3: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    // [theme.breakpoints.down("sm")]: {
    //   alignItems: "center"
    // },
    "& h5": {
      fontFamily: "'Roboto', sans-serif",
      textTransform: "capitalize",
      fontSize: "18px",
      textAlign: "left",
      marginLeft:"10px"
    },
    "& p": {
      color: "#898989",
      padding: "5px 0px",
      fontFamily: "'Roboto', sans-serif",
      cursor: "pointer",
      fontSize: "15px",
      fontWeight: "400",
      wordBreak: "break-all",
      "&:hover": {
        color: "#FFFFFF"
      }
    },
    "& ul": {
      "& li": {
        "& a": {
          display: "flex",
          alignItems: "center",
          "&:hover": {
            textDecoration: "none",
            color: "#fff"
          }
        }
      }
    },
  },

  footerlogoImage: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "flex-start",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
    "& figure": {
      margin: "0px",
      "& img": {
        width: "70px",
      }
    },
    "& p": {
      fontSize: "15px",
      color: "#898989",
      lineHeight:"25px",
      textAlign: "left"
    }
  },

  ContactUsText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    "& svg": {
      margin: "8px",
      fontSize: "18px"
    },
    "& a": {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      "&:hover": {
        textDecoration: "none"
      },
      "& svg": {
        color: "#ffffff"
      }
    }
  },
  footerQuickLinkText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
    }
  },
  footerContactUsText: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "end"
  }
}));

export default function Liquidity() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <Box className={classes.root}>
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} align="center" >
              <Box className={classes.footerlogoImage}>
                <RouterLink to="/" style={{ color: "#ffffff", textDecoration: "none" }}>
                  <Logo className="logoImg" />
                </RouterLink>
                <Box mt={3}>
                  <Typography variant="body2" >
                    Enhancing the world of Crypto by offering a platform which allows individuals to buy, hold, exchange, and sell cryptocurrency.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            {/* <Grid item xs={6} md={3} align="center">
              <Box className={classes.footerText1}>
                <Typography variant="h5">Social Link</Typography>
                <List>
                  <ListItem>
                    <Link target="_blank" href="https://www.reddit.com/">
                      <AiOutlineReddit /> &nbsp;&nbsp;&nbsp;Reddit
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link target="_blank" href="https://twitter.com/">
                      <AiOutlineTwitter />&nbsp;&nbsp;&nbsp;Twitter
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link target="_blank" href="https://discord.com/">
                      <RiDiscordFill />&nbsp;&nbsp;&nbsp;Discord
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link target="_blank" href="https://www.youtube.com/">
                      <IoLogoYoutube />&nbsp;&nbsp;&nbsp;Youtube
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link target="_blank" href="https://telegram.org/">
                      <FaTelegramPlane />&nbsp;&nbsp;&nbsp;Telegram
                    </Link>
                  </ListItem>
                </List>
              </Box>
            </Grid> */}
            <Grid item xs={6} md={4} align="center">
              <Box className={classes.footerQuickLinkText} >
                <Box className={classes.footerText2}>
                  <Typography variant="h5">Quick Links</Typography>
                  <Box mt={2} style={{
                    display: "flex",
                    alignItems: "baseline",
                    justifyContent: "flex-start",
                    flexDirection: "column"
                  }}>
                    <Typography variant="body2" onClick={() => history.push("/terms-conditions")}>Terms and Conditions</Typography>
                    <Typography variant="body2" onClick={() => history.push("/privacy-policy")}>Privacy Policy</Typography>
                    <Typography variant="body2" onClick={() => history.push("/about-us")}>About us</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} md={4} align="right">
              <Box className={classes.footerContactUsText}>
                <Box className={classes.footerText3}>
                  <Typography variant="h5">Contact Info</Typography>
                  <Box mt={2}>
                    <Grid container spacing={1} direction={"column"}>
                      <Grid item xs={12}>
                        <Box className={classes.ContactUsText}>
                          <LocationOnIcon />
                          <Typography variant="body2">Vilnius, Liaises pr.60, Lithuania
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box className={classes.ContactUsText}>
                          <FaMobile />
                          <Typography variant="body2">+372 618 8347</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box className={classes.ContactUsText}>
                          <Link href="mailto:contact@website.com">
                            <MdEmail />
                            <Typography variant="body2">support@procryptowallet.com</Typography>
                          </Link>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
